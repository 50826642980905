import React, { useEffect, useState } from "react";
import { Canvas3D } from "../components/Canvas3D";
import "../styles/style.css";
import CursorContextProvider from "../components/CursorContextProvider";
import { Cursor } from "../components/Cursor";
import { BannerRowLeft, BannerRowRight } from "../components/Layout";
import { Nav } from "../components/Nav";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import GifVideo from "../components/GifVideo";
import indexVid1 from '../videos/Blender/blendily_8.mp4'
import indexVid3 from '../videos/Blender/blendily_3.mp4'
import indexVid4 from '../videos/Blender/blendily_16.mp4'

const IndexPage = () => {
  const indexVid = [indexVid1, indexVid1, indexVid1, indexVid1]
  const randomVid = indexVid[Math.floor(Math.random() * indexVid.length)];
  return (
    <CursorContextProvider>
      <Cursor />
      <GifVideo src={randomVid} style={{objectFit:'cover', height:'100vh', width:'100vw'}}/>
 
      <Nav />
      <div className="indexMain">
        <title>Manatee - Portfolio</title>
        <BannerRowLeft txt={"M A N A T E E"} />
        <BannerRowRight
          txt1={"_紅色肚子的海牛"}
          txt2={"Designer. [ #5 ] * Manatee"}
        />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { duration: 1.2, ease: [0.37, 0, 0.63, 1] },
        }}
        viewport={{ once: true }}
        className="indexFooter"
      >
        © [2022] Manatee. ʕ◉ᴥ◉ʔ
        <br />
        {/* <StaticImage src='../images/备案图标.png' width={16}/> */}
        {/* <p style={{display:'inline-block',fontSize:'0.8rem'}} ><a href="https://beian.miit.gov.cn">浙ICP备2022008827号-1</a></p> */}
      </motion.div>
    </CursorContextProvider>
  );
};

export default IndexPage;
